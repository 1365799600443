import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Colors,
} from "chart.js";
import "./StudentReport.css"; // Use the same CSS file if necessary
import { accurate, cap, triagleScale } from "./assets";
// import triangle from '../public/Images/triagleScale.png'

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const StatusBar = ({ status }) => {
  
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "strong":
        return "#61BCA4";
      case "medium":
        return "#FDCE36";
      case "weak":
        return "#E5257B";
      case "average":
        return "#833D8D";
      default:
        return "gray";
    }
  };

  const widthMap = {
    strong: "100%",
    medium: "100%",
    average: "100%",
    weak: "100%",
  };

  return (
    <div className="gap-2" style={{ display: "flex", alignItems: "center" }}>
      <span>{status}</span>
      <div
        style={{
          backgroundColor: getStatusColor(status),
          height: "10px",
          width: widthMap[status.toLowerCase()] || "0%",
          borderRadius: "5px",
          marginRight: "10px",
        }}
      ></div>
    </div>
  );
};

const StudentReport = () => {
  const { id, lessonId } = useParams(); // Include lessonId in route params if needed
  const [report, setReport] = useState({
    totalqquestions: 0,
    totalpoints: 0,
    accuracy: 0,
  });
  const [questionReport, setQuestionReport] = useState([]); // New state for question report
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(questionReport);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentreport`,
          {
            method: "POST",
            headers: {
              Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
              "Content-Type": "application/json",
              jwt: "your-jwt-token",
            },
            body: JSON.stringify({ Studentid: id, Lessonid: lessonId }),
          }
        );
        console.log(" api ", reportResponse);
        if (!reportResponse.ok) {
          throw new Error("Not Attempted Yet");
        }

        const reportResult = await reportResponse.json();
        console.log("slkdjfls",report)
        setReport(reportResult.data);
        setQuestionReport(reportResult.questionreport || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [id, lessonId]);

  const calculateStatusCounts = () => {
    const counts = { strong: 0, medium: 0, weak: 0, average: 0 };
    questionReport.forEach((item) => {
      const status = item.status.toLowerCase();
      if (counts[status] !== undefined) {
        counts[status] += 1;
      }
    });
    return counts;
  };

  const statusCounts = calculateStatusCounts();

  const pieData = {
    labels: ["Strong", "Medium", "Average", "Weak"],
    datasets: [
      {
        data: [
          statusCounts.strong,
          statusCounts.medium,
          statusCounts.average,
          statusCounts.weak,
        ],
        backgroundColor: ["#61BCA4", "#FDCE36", "#833D8D", "#E5257B"],
      },
    ],
  };

  if (loading) return <p className="loading-message">Loading...</p>;

  if (error) return <p className="error-message">Error: {error}</p>;
  console.log(questionReport[0].question);
  return (
    <div className="pt-[1.5rem] pr-[7.5rem] pb-[1.5rem] pl-[7.5rem]  mx-auto min-h-screen bg-[#FAF4E5]"
>
      <div className="student-report">
        <img
          src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`} 
          alt="Logo"
          style={{
            width: "263px",
            height: "auto",
            padding: "20px"

          }}
        />

        <div className="student-report flex flex-col items-center gap-9">
          
          <div
            className="flex flex-row justify-between w-[80%] mt-10 rounded-lg bg-gray-700 h-[100%]"
            style={{
              background: "#FFD391",
              borderRadius: "12px", // or use a larger value if necessary

            }}
          >
            <div>
              <div className="flex flex-col h-[100%] justify-between">
                <div className="flex gap-4 p-4 items-center rounded-lg">
                  <img src={cap} className="w-[40px] h-[40px]" />

                  <h2>Performance Per Lesson</h2>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col gap-5 bg-[#FAF4E5] pt-[18px] pr-[10px] pb-[40px] pl-0 rounded-tr-[10px] w-[600px] ">
                    <div className="bg-[#D9EA49] flex p-2 justify-between rounded-lg">
                      <div className="flex gap-3 ">
                        <img
                          src={triagleScale}

                          alt="slkdjfl"
                          className="w-[40px] h-[40px]"
                        ></img>
                        <div>
                          <p>Total Questions</p>
                          <p>
                            Note: The statistics is based on all the questions
                          </p>
                        </div>
                      </div>
                      <p className="text-white bg-[#040341] w-16 h-16 rounded-full flex items-center justify-center text-[20px] font-semibold leading-normal">
                        {questionReport.length}
                      </p>
                    </div>
                    <div className="bg-[#D9EA49] flex p-2 justify-between rounded-lg">
                      <div className="flex gap-3">
                      <img
                        src={accurate}

                        alt="slkdjfl"
                        className="w-[40px] h-[40px]"
                      ></img>
                      <div>
                        <p>Accuracy</p>
                        <p>
                        Note: The accuracy is calculated on the basis of per attempt
                        </p>
                      </div>
                      </div>
                      <p className="text-white bg-[#040341] w-16 h-16 rounded-full flex items-center justify-center text-[20px] font-semibold leading-normal">
                        {`${report.accuracy.toFixed(0)}%`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pie-chart-container">
              <div className="chart">
                <Pie data={pieData} width={200} height={200} />
              </div>
            </div>
          </div>

          <div
  className="w-[80%] rounded-lg"
  style={{
    background: "#FFF",
    padding: "20px",
  }}
>
  {questionReport.length > 0 ? (
    <table className="question-report-table w-full table-auto border-collapse">
      <thead className="bg-gray-100">
        <tr>
          <th className="border px-4 py-2 text-center">S.No</th> {/* Centered header */}
          <th className="border px-4 py-2 text-center">Question</th> {/* Centered header */}
          <th className="border px-4 py-2 text-center">Status</th> {/* Centered header */}
        </tr>
      </thead>
      <tbody>
        {questionReport.map((item, index) => (
          <tr key={index}>
            <td className="border px-4 py-2 text-center">{index + 1}</td> {/* Centered S.No */}
            <td className="border px-4 py-2 text-center">{item.question}</td> {/* Centered Question */}
            <td className="border px-4 py-2 text-center">
              <StatusBar status={item.status} /> {/* Centered Status */}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>No question report available</p>
  )}
</div>


        </div>
      </div>
    </div>
  );
};

export default StudentReport;
