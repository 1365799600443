import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const QuestionSelection = () => {
  const [selectedQuestions, setSelectedQuestions] = useState([]); 
  const [showModal, setShowModal] = useState(false); 
  const [loading, setLoading] = useState(false); // Set loading to false for dummy data
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedYearId, setSelectedYearId] = useState(1);
  const [question,setQuestion]=useState([]);
  const[modelQuestion , setmodelQuestion]=useState([])
  const [currentYear , setcurrentYear]=useState("")
  const [nextPagedata,setnextPagedata]=useState({})
  const [count,setCount]=useState(0);
  console.log("sdfksdfkjslkdfjlsjflksjdflksdf======>",question)
  

  const location =useLocation();
  const {AssignmentId,yearId,yearID,title,description,adminid,firstname}= location.state ||{};
  



  const yearName=()=>{
    console.log("Inside year Name :",yearID)
    if(yearId=="1"){
      setcurrentYear("Foundation")
    }
    else{
      setcurrentYear(`Year ${yearID-1}`)
    }
    console.log(currentYear,"is the curr year")
  }

  const handleSelectAll = () => {
    if (selectedQuestions.length === question.length) {
      // Deselect all
      setSelectedQuestions([]);
    } else {
      // Select all
      setSelectedQuestions(question);
    }
  };
  


  


  const handleYearChange = (event) => {


    const newYearId = Number(event.target.value);
    if (newYearId !== selectedYearId) {
      setSelectedYearId(newYearId);
      // localStorage.setItem(`selectedYear_${id}`, newYearId);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/questionassementOfYear`,
        {
          method: "POST",
          // mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
            body: JSON.stringify({ YearId:yearID})
        }
      );
      
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch data");
      }
  
      const jsonData = await response.json();
      setQuestion(jsonData.data); 
      console.log("question==========>:",jsonData)

    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };



 
  // console.log("sjdflkssdjflk=>",question)

  const handleCheckboxChange = (question) => {
    console.log("inside handle check box change",question)
    if (selectedQuestions.includes(question)) {
      setSelectedQuestions(selectedQuestions.filter(q => q !== question));
    } else {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };

  

  useEffect(() => {
    fetchData();
    yearName();

  }, [yearID]);
 

  const handleSaveAndNext = () => {
    navigate('/assignMarks' ,{ state: { selectedQuestions , assignmentId:AssignmentId,yearId:yearID,title,description,adminid,firstname} });
  };

  









useEffect(() => {
  setCount(selectedQuestions.length)
}, [selectedQuestions]);


  return (
    <div className="p-6 bg-[#FEF4DB] min-h-screen">
      <h1 className="text-xl font-semibold mb-4">Select Questions</h1>

      {/* Tab Navigation (optional) */}
      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        <p className="px-4 py-2 rounded-lg font-semibold">Task Details</p>
        <p className="px-4 py-2 rounded-lg font-semibold ">Assign to</p>
        <p className="px-4 py-2 rounded-lg font-semibold bg-[#ffcd47] text-black">Select Question</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign Marks</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign Timeline</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Publish & Assign</p>
      </div>

      {/* Question List Table */}
      <div className='flex justify-end mr-[10px]'>
  <h1>Count: {selectedQuestions.length}</h1>
  </div>
      <div className="overflow-x-auto border rounded-lg shadow-sm">
        
  <div className="max-h-96 overflow-y-auto bg-white">
    

    <table className="min-w-full ">

      <thead>
        <tr>
          <th className="px-6 py-4 text-left text-gray-600 font-semibold">
            <input
              type="checkbox"
              checked={selectedQuestions.length === question.length && question.length > 0}
              onChange={handleSelectAll}
              className="form-checkbox text-blue-500"
            />
            <span className="ml-2">Select All</span>
          </th>
          <th className="px-6 py-4 text-left text-gray-600 font-semibold">Title</th>
          <th className="px-6 py-4 text-left text-gray-600 font-semibold">Year</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan="4" className="px-6 py-4 text-center">Loading...</td>
          </tr>
        ) : error ? (
          <tr>
            <td colSpan="4" className="px-6 py-4 text-center text-red-500">{error}</td>
          </tr>
        ) : question.length > 0 ? (
          question.map((item) => (
            <tr key={item.id} className="border-b">
              <td className="px-6 py-4">
                <input
                  type="checkbox"
                  checked={selectedQuestions.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                  className="form-checkbox text-blue-500"
                />
              </td>
              <td className="px-6 py-4">{item.question}</td>
              <td className="px-6 py-4">{currentYear}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="px-6 py-4 text-center">No questions found.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>


      {/* Bottom Buttons */}
      <div className="flex justify-evenly items-center mt-6">
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
          onClick={() => setShowModal(true)}
        >
          Preview Selected List
        </button>
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
          onClick={handleSaveAndNext}
          
        >
          Save & Next
        </button>
      </div>

      {/* Modal */}
      {showModal && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white w-3/4 rounded-lg p-6">
      <h2 className="text-xl font-bold mb-4">Selected Question List</h2>

      {/* Scrollable Table Container */}
      <div className="max-h-96 overflow-y-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">S No.</th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">Title</th>
            </tr>
          </thead>
          <tbody>
            {selectedQuestions.map((item, index) => (
              <tr key={item.id} className="border-b">
                <td className="px-6 py-4">{index + 1}</td>
                <td className="px-6 py-4">{item.question}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="mt-4 bg-gray-600 text-white px-4 py-2 rounded"
        onClick={() => setShowModal(false)}
      >
        Close
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default QuestionSelection;
