import "./App.css";

import ProtectedRoute from "./context/ProtectedRoute"; // Import ProtectedRoute
import Login from "./login";
import Signup from "./signup";
import Dashboard from "./dashboard";
import Question from "./question";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Insight from "./Insight";
import StudentDetails from "./StudentDetails";
import StudentReport from "./StudentReport";
import AssignTime from "./AssignTime";
import Generate from "./Generate";
import RequestTime from "./RequestTime";
import Lessons from "./Lessons";
import Assignment from "./Assignment";
import AddAssignment from "./AddAssignment";
import FileUpload from "./FileUpload";
import TaskDetail from "./TaskDetail";
import AssignTo from "./AssignTo";
import QuestionSelection from "./QuestionSelection";
import AssignMarks from "./AssignMarks";
import AssignTimeline from "./AssignTimeline";
import Publish from "./Publish";
import Worksheet from "./components/Worksheet";
import Library from "./components/Library";
import AssignmentDetailReport from "./components/AssignmentDetailReport";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/signup"
            element={
                <Signup />
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route
            path="/question"
            element={
              <ProtectedRoute>
                <Question />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insight"
            element={
              <ProtectedRoute>
                <Insight />
              </ProtectedRoute>
            }
          />
          <Route
            path="/generate"
            element={
              <ProtectedRoute>
                <Generate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assign"
            element={
              <ProtectedRoute>
                <AssignTime />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/:id"
            element={
              <ProtectedRoute>
                <StudentDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/:id/report/:lessonId"
            element={
              <ProtectedRoute>
                <StudentReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/requesttime/:userid"
            element={
              <ProtectedRoute>
                <RequestTime />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lessons"
            element={
              <ProtectedRoute>
                <Lessons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignment"
            element={
              <ProtectedRoute>
                <Assignment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignment/addAssignment"
            element={
              <ProtectedRoute>
                <AddAssignment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/fileUpload"
            element={
              <ProtectedRoute>
                <FileUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignment/titleandDesc"
            element={
              <ProtectedRoute>
                <TaskDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignTo"
            element={
              <ProtectedRoute>
                <AssignTo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/selectQuestion"
            element={
              <ProtectedRoute>
                <QuestionSelection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignMarks"
            element={
              <ProtectedRoute>
                <AssignMarks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignTimeline"
            element={
              <ProtectedRoute>
                <AssignTimeline />
              </ProtectedRoute>
            }
          />
          <Route
            path="/publish"
            element={
              <ProtectedRoute>
                <Publish />
              </ProtectedRoute>
            }
          />
          <Route
            path="/worksheet"
            element={
              <ProtectedRoute>
                <Worksheet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library"
            element={
              <ProtectedRoute>
                <Library />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assignmentReport"
            element={
              <ProtectedRoute>
                <AssignmentDetailReport />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
