import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskDetail from './TaskDetail';
import AssignTo from './AssignTo';
import QuestionSelection from './QuestionSelection';

const AddAssignment = () => {
  const [selectedTab, setSelectedTab] = useState('taskDetail');
  const navigate = useNavigate();

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'taskDetail':
        return <TaskDetail />;
      case 'assignTo':
        return <AssignTo />;
      case 'selectQuestion':
        return <QuestionSelection />;
      // case 'assignMarks':
      //   return <AssignMarks />;
      // case 'assignTimeline':
      //   return <AssignTimeline />;
      // case 'publishAssign':
      //   return <PublishAssign />;
      // default:
        return <TaskDetail />;
    }
  };

  return (
    <div className="p-6 bg-[#FEF4DB]">
      <h1 className="text-2xl font-bold mb-4">Assignment</h1>

      {/* Tab Navigation */}
      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        <button
          onClick={() => setSelectedTab('taskDetail')}
          className={`px-4 py-2 rounded-lg font-semibold ${selectedTab === 'taskDetail' ? 'bg-[#ffcd47] text-black' : ''}`}
        >
          Task Details
        </button>
        <button
          onClick={() => setSelectedTab('assignTo')}
          className={`px-4 py-2 rounded-lg font-semibold ${selectedTab === 'assignTo' ? 'bg-[#ffcd47] text-black' : ''}`}
        >
          Assign to
        </button>
        <button
          onClick={() => setSelectedTab('selectQuestion')}
          className={`px-4 py-2 rounded-lg font-semibold ${selectedTab === 'selectQuestion' ? 'bg-[#ffcd47] text-black' : ''}`}
        >
          Select Question
        </button>
        <button
          onClick={() => setSelectedTab('assignMarks')}
          className={`px-4 py-2 rounded-lg font-semibold ${selectedTab === 'assignMarks' ? 'bg-[#ffcd47] text-black' : ''}`}
        >
          Assign Marks
        </button>
        <button
          onClick={() => setSelectedTab('assignTimeline')}
          className={`px-4 py-2 rounded-lg font-semibold ${selectedTab === 'assignTimeline' ? 'bg-[#ffcd47] text-black' : ''}`}
        >
          Assign Timeline
        </button>
        <button
          onClick={() => setSelectedTab('publishAssign')}
          className={`px-4 py-2 rounded-lg font-semibold ${selectedTab === 'publishAssign' ? 'bg-[#ffcd47] text-black' : ''}`}
        >
          Publish & Assign
        </button>
      </div>

      {/* Render Tab Content */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default AddAssignment;
