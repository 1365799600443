import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { accurate, cap, triagleScale } from "../assets";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const AssignmentDetailReport = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [worksheetReport, setWorksheetReport] = useState(null);

  const location = useLocation();
  const { assignmentId, studentId } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/assignmentDetailReport`, {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
          },
          body: JSON.stringify({ assignmentId, studentId }),
        });

        if (!response.ok) throw new Error("Failed to fetch data");

        const result = await response.json();
        setWorksheetReport(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [assignmentId, studentId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Prepare pie chart data
  const pieData = {
    labels: ["Correct Answers", "Incorrect Answers"],
    datasets: [
      {
        label: "Accuracy",
        data: [worksheetReport.correct_answers, worksheetReport.incorrect_answer],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };



  return (
    <div className="pt-[1.5rem] pr-[7.5rem] pb-[1.5rem] pl-[7.5rem]  mx-auto min-h-screen bg-[#FAF4E5]">
      <div className="student-report">
        <img
          src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`} 
          alt="Logo"
          style={{
            width: "250px",
            height: "auto",
          }}
        />
        <div className="student-report flex flex-col items-center gap-9">
          <div
            className="flex flex-row justify-between w-[80%] mt-10 rounded-lg bg-gray-700 h-[100%]"
            style={{
              background: "#FFD391",
              borderRadius: "12px",
            }}
          >
            <div>
              <div className="flex flex-col h-[100%] justify-between">
                <div className="flex gap-4 p-4 items-center rounded-lg">
                  <img src={cap} className="w-[40px] h-[40px]" />
                  <h2>Performance Per Lesson</h2>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col gap-5 bg-[#FAF4E5] pt-[18px] pr-[10px] pb-[40px] pl-0 rounded-tr-[10px] w-[600px] ">
                    <div className="bg-[#D9EA49] flex p-2 justify-between rounded-lg">
                      <div className="flex gap-3 ">
                        <img
                          src={triagleScale}
                          alt="Icon"
                          className="w-[40px] h-[40px]"
                        />
                        <div>
                          <p>Total Questions</p>
                          <p>
                            Note: The statistics is based on all the questions.
                          </p>
                        </div>
                      </div>
                      <p className="text-white bg-[#040341] w-16 h-16 rounded-full flex items-center justify-center text-[20px] font-semibold leading-normal">
                        {worksheetReport?.total_questions}
                      </p>
                    </div>
                    <div className="bg-[#D9EA49] flex p-2 justify-between rounded-lg">
                      <div className="flex gap-3">
                        <img
                          src={accurate}
                          alt="Icon"
                          className="w-[40px] h-[40px]"
                        />
                        <div>
                          <p>Accuracy</p>
                          <p>
                            Note: The accuracy is calculated on the basis of
                            each attempt.
                          </p>
                        </div>
                      </div>
                      <p className="text-white bg-[#040341] w-16 h-16 rounded-full flex items-center justify-center text-[20px] font-semibold leading-normal">
                      {Math.floor(worksheetReport?.accuracy_percentage)}%

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pie-chart-container">
              <div className="chart">
                <Pie data={pieData} width={200} height={200} />
              </div>
            </div>
          </div>

          <div
            className="w-[80%] rounded-lg"
            style={{
              background: "#FFF",
              padding: "20px",
            }}
          >
            {worksheetReport.questions.length > 0 ? (
  <table className="table-auto border-collapse border border-gray-300 text-sm w-full">
    <thead>
      <tr className="bg-gray-200 text-center">
        <th className="border border-gray-300 p-2">Question</th>
        <th className="border border-gray-300 p-2">Marks</th>
        {/* Uncomment this if needed */}
        {/* <th className="border border-gray-300 p-2">Status</th> */}
      </tr>
    </thead>
    <tbody>
      {worksheetReport.questions.map((item, index) => (
        <tr key={index} className="text-center">
          <td className="border border-gray-300 p-2 w-[70%]">{item.question || "N/A"}</td>
          <td className="border border-gray-300 p-2">{item.marks || 0}</td>
          {/* Uncomment this if needed */}
          {/* <td className="border border-gray-300 p-2">{item.is_correct ? "Correct" : "Incorrect"}</td> */}
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p className="text-center text-gray-500">No questions available.</p>
)}

          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentDetailReport;
