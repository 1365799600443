import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Worksheet = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  // Function to fetch the assignment data
  const fetchData = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("assignmentId", id);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/assignmentreport`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Fetched Data:", result.data);
      setData(result.data || []);
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount or when `id` changes
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  // Loading, error, or no data states
  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500">Error: {error}</div>;
  if (data.length === 0)
    return <div className="text-center py-4">No data available</div>;

  return (
    <div className="pt-[1.5rem] pr-[7.5rem] pb-[1.5rem] pl-[7.5rem] mx-auto min-h-screen bg-[#FAF4E5]">
      <div className="student-report flex flex-col items-center gap-9">
        <img
          className="logo-stuudent"
          src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`}
          alt="Logo"
          style={{
            width: "250px",
            height: "auto",
          }}
        />
        <h1 className="text-2xl font-semibold text-center mt-[50px] mb-6">
          Worksheet
        </h1>
        <div
          className="w-[80%] rounded-lg bg-white p-4 shadow-lg"
          style={{
            borderRadius: "12px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-center">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-300 p-2 font-semibold text-gray-700">
                  Name
                </th>
                <th className="border border-gray-300 p-2 font-semibold text-gray-700">
                  Total Marks
                </th>
                <th className="border border-gray-300 p-2 font-semibold text-gray-700">
                  Obtained Marks
                </th>
                <th className="border border-gray-300 p-2 font-semibold text-gray-700">
                  Completion Status
                </th>
                <th className="border border-gray-300 p-2 font-semibold text-gray-700">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="border-b hover:bg-gray-50">
                  <td className="border border-gray-300 p-2 text-sm text-gray-800">
                    {item.student_name}
                  </td>
                  <td className="border border-gray-300 p-2 text-sm text-gray-800">
                    {item.total_marks}
                  </td>
                  <td className="border border-gray-300 p-2 text-sm text-gray-800">
                    {item.obtained_marks}
                  </td>
                  <td className="border border-gray-300 p-2 text-sm text-gray-800">
                    {item.completionStatus ? (
                      <span className="text-green-600 font-medium">Completed</span>
                    ) : (
                      <span className="text-red-600 font-medium">Not Completed</span>
                    )}
                  </td>
                  <td className="border border-gray-300 p-2 text-sm text-gray-800 flex justify-center items-center">
                    <button
                      className={`text-[#08059B] font-poppins text-sm font-medium leading-none tracking-tight rounded border border-[#08059B] flex justify-center items-center gap-2 px-4 py-2 no-underline cursor-pointer transition-transform transform hover:scale-105 hover:bg-[rgba(38,24,107,0.13)] shadow hover:shadow-lg ${
                        !item.completionStatus ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => {
                        navigate("/assignmentReport", {
                          state: { studentId: item.student_id, assignmentId: id },
                        });
                      }}
                      disabled={!item.completionStatus}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Worksheet;
