import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Create the AuthContext with default value
const AuthContext = createContext({
  isAuthenticated: null,
  user: null,
  login: () => {},
  logout: () => {},
});

// AuthProvider component to wrap the application
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially null
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Check for user data in localStorage on mount
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Failed to parse user data from localStorage:", error);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // Login function: store user in state and localStorage
  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    navigate("/dashboard");
  };

  // Logout function: clear user from state and localStorage
  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access AuthContext
export const useAuth = () => React.useContext(AuthContext);
