import React, { useState, useEffect } from 'react';

  const Generate = () => {
    const [yearData, setYearData] = useState([]);
    const [lessonTopics, setLessonTopics] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [questionTypes, setQuestionTypes] = useState([]);
    const [selectedQuestionType, setSelectedQuestionType] = useState(''); // New state variable for question type
    const [selectedLevel, setSelectedLevel] = useState('');
    const [generatedQuestions, setGeneratedQuestions] = useState(''); // State to store generated questions
    const [selectedYearName, setSelectedYearName] = useState('');
    const [topicName, setTopicName]=useState('');

    

    console.log(selectedTopic)


    
    // console.log(process.env.REACT_APP_JWT)


    useEffect(() => {
      const fetchYearData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/genratequestionYeardata`, {
            method: 'POST',
            headers: {
              'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
              'Content-Type': 'application/json',
              jwt: `${process.env.REACT_APP_JWT}`,
              
            },
          });
  
          const data = await response.json();
  
          if (data.status_code === "200") {
            setYearData(data.yearlist);
          } else {
            throw new Error('Failed to fetch year data');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchYearData();
    }, []);
  
    const fetchLessonTopics = async (yearId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/getLessontopicList`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            jwt: `${process.env.REACT_APP_JWT}`,
          },
          body: JSON.stringify({ yearId }),
        });
    
        const data = await response.json();
    
        if (data.status_code === "200") {
          // Filter out "Test Lesson" topics
          const filteredTopics = data.yearlist.filter(topic => !topic.topics.includes('Test Lesson'));
          setLessonTopics(filteredTopics);
        } else {
          throw new Error('Failed to fetch lesson topics');
        }
      } catch (err) {
        setError(err.message);
      }
    };
    
  
    const fetchQuestionTypes = async (lessonId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/getQuestionTypeList`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            jwt: `${process.env.REACT_APP_JWT}`,
          },
          body: JSON.stringify({ lessonId })
        });
    
        const data = await response.json();
    
        if (data.status_code === "200") {
          // Use reduce to collect unique question types
          const uniqueQuestionTypes = data.yearlist.reduce((acc, item) => {
            if (!acc.some(i => i.questionType === item.questionType)) {
              acc.push(item);
            }
            return acc;
          }, []);
    
          setQuestionTypes(uniqueQuestionTypes);
        } else {
          throw new Error('Failed to fetch question types');
        }
      } catch (err) {
        setError(err.message);
      }
    };
    
  
    const handleYearChange = (e) => {
      
      const yearId = e.target.value;
      const YearName=yearData.filter((i)=>console.log("fiter",i.yearID===yearId))
      console.log(YearName)
      setSelectedYear(yearId);
      setSelectedTopic(''); 
      setSelectedQuestionType(''); // Reset selected question type
      setQuestionTypes([]); 
      if (yearId) {
        fetchLessonTopics(yearId); 
      } else {
        setLessonTopics([]); 
      }
    };
  
    const handleTopicChange = (e) => {
      const topicId = e.target.value;

      console.log("lslfkjslkdfjlskjdflksjldfk lesson topic",topicId)

      
      // Find the selected topic name
      const topic = lessonTopics.find((t) => t.Id == topicId);
      console.log("topic....=>",topic)
      
      if (topic) {
        setTopicName(topic.topics); // Store the topic name
      }
      console.log("topic Name:",topicName)
      setSelectedTopic(topicId);
      
      setSelectedQuestionType(''); // Reset selected question type when the topic changes
      if (topicId) {
        fetchQuestionTypes(topicId); 
      } else {
        setQuestionTypes([]); 
      }
    };
    
  
    const handleQuestionTypeChange = (e) => {
      setSelectedQuestionType(e.target.value); // Update selected question type
    };
  
    const handleLevelChange = (e) => {
      setSelectedLevel(e.target.value); 
    };
  
    const handleGenerateQuestions = async () => {
      if (!selectedYear || !selectedTopic || !selectedQuestionType || !selectedLevel) {
        setError('Please select all fields before generating questions.');
        return;
      }
      const yearLabel = selectedYear === '1' ? 'Foundation' : ` ${selectedYear -1}`;

      // const content = `I want 1 question based on ${topicName} of ${selectedQuestionType} type for level ${selectedLevel} for the year ${selectedYear} on the basis of the VIC Australian curriculum. Don't generate anything extra at the start or end; just give me the question and four options.`;
      const content = `Generate 1 question for year ${yearLabel} for ${topicName},with ${selectedQuestionType} with ${selectedLevel} based on VIC Mathematics Australian curriculam. Don't generate anything extra`;
      // const content=prompt;
      console.log("hi",process.env.REACT_APP_BASE_URL)

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/chatgptbtoc`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            jwt: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMyOTU4NjQyLCJpYXQiOjE3MzI5NTgzNDIsImp0aSI6ImJhYzdiMzJhODA3ZDRhMzg5ZDA2MTFiMDBiNTE5MGI0IiwidXNlcl9pZCI6Ik5vbmUifQ.eSMQHmXxVu1u3sWNss3dW1dMzTwowAB4GYPjAGy9o34
`,
          },
          body: JSON.stringify({ content })
        });
    
        const data = await response.json();
        console.log(data.data.generated_text)
    
        if (data.status_code === "200") {
          // Extract the question from the response
          const generatedQuestion = data.data.generated_text;
          setGeneratedQuestions(generatedQuestion); // Store the question as an array
          setError(null);
        } else {
          throw new Error('Failed to generate questions');
        }
      } catch (err) {
        setError(err.message);
      }
    };
    
    const handleSaveQuestion = async () => {
      console.log(generatedQuestions)
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/saveQuestion`, {
          method: 'POST',
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            jwt: `${process.env.REACT_APP_JWT}`,
          },
          body: JSON.stringify({question: generatedQuestions})

        });

        console.log(response)
        if(response.ok){
          alert("Question Saved Sucessfully")
        }

    }
    catch (err) {
      setError(err.message);
    }
  }
  
    console.log("nasdfsfman",topicName)
  
    if (loading) return <div className="text-center text-lg">Loading...</div>;
    // if (error) return <div className="text-red-500 text-center">{error}</div>;
  
    return (
      <div className='bg-[#FAF4E5] min-h-screen pt-[1.6rem]'>
        <img className='logo-stuudent' src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`}  alt="Logo"style={{ paddingLeft: "20px" }} />
        <h1 className='text-3xl font-semibold text-center mt-[50px] mb-6' style={{ marginTop: "7px" }}>Generate Question</h1>
      <div className="max-w-[56rem] mx-auto p-6 border rounded-lg shadow-md bg-[white] mt-[40px]">
        <h2 className="text-xl font-semibold mb-4">Select Year</h2>
        <select
          value={selectedYear}
          onChange={handleYearChange}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a year</option>
          {yearData.map((year) => (
            <option key={year.yearID} value={year.yearID}>
              {year.year} 
            </option>
          ))}
        </select>
  
        <h2 className="text-xl font-semibold mb-4">Select Lesson Topic</h2>
        <select
          value={selectedTopic}
          onChange={handleTopicChange}
          disabled={!selectedYear}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a topic</option>
          {lessonTopics.map((topic) => (
            <option key={topic.Id} value={topic.Id}>
              {topic.topics}
            </option>
          ))}
        </select>
  
        <h2 className="text-xl font-semibold mb-4">Select Question Type</h2>
        <select
          value={selectedQuestionType} // Bind the selected value
          onChange={handleQuestionTypeChange} // Add change handler
          disabled={!selectedTopic}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a question type</option>
          {questionTypes.map((questionType) => (
            <option key={questionType.questionType} value={questionType.questionType}>
              {questionType.questionType}
            </option>
          ))}
        </select>
  
        <h2 className="text-xl font-semibold mb-4">Select Level</h2>
        <select
          value={selectedLevel}
          onChange={handleLevelChange}
          className="mb-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select level</option>
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
        </select>
        {/* <input type='text' className='w-[100%] border rounded-sm	' placeholder='Enter Prompt.' onChange={(e)=>(setPrompt(e.target.value))}></input> */}

        
        <button
        onClick={handleGenerateQuestions} // Call the API on button click
        className="w-full bg-blue-500 text-white font-semibold py-2 rounded hover:bg-blue-600 "
      >
        Generate Questions
      </button>
      {/* {generatedQuestions.length >= 0 && ( */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Generated Question</h2>
        <div className="border-b py-2">
          <p>{generatedQuestions}</p>
        </div>
         <button onClick={handleSaveQuestion} className='w-full bg-blue-500 text-white font-semibold py-2 rounded hover:bg-blue-600'>Save Question</button>

      </div>
    {/* )} */}

      </div>
      </div>
    );
  };
  
  export default Generate;
  