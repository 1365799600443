// Sidebar.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { llogo } from "./assets";
const Sidebar = ({ selectedCard, setSelectedCard, handleInsightRedirect, handleSignupRedirect ,adminid,firstname}) => {
  const navigate = useNavigate(); 
  console.log(adminid)
  return (
    <div className="sidebar" >
      <div className="logo">
        <img
          src={llogo}
          alt="Logo"
          className="card-image"
        />
      </div>
      <div className="w-[100%]">
        <div className="card-container">
          <div
          >
            <p className="insight-button w-[100%]" onClick={() => navigate('/dashboard',{state:{firstname}})}>
              Dashboard
            </p>
          </div>
        </div>
        <button className="insight-button w-[100%]" onClick={handleInsightRedirect}>
          Insights
        </button>
        <button className="insight-button w-[100%]" onClick={() => navigate('/assign',{state:{adminid}})}>
          Assign Time
        </button>
        <button className="insight-button w-[100%]" onClick={() => navigate('/generate')}>
          Generate Question
        </button>
        <button className="insight-button w-[100%]" onClick={() => navigate('/assignment',{state:{adminid,firstname}}) }>
          Assignment
        </button>
        {/* <button className="insight-button w-[100%]" onClick={() => navigate('/fileUpload')}>
          FileUpload
        </button> */}
        <button className="insight-button w-[100%]" onClick={() => navigate('/library')}>
          My Library
        </button>
      </div>
      <button className="logout-button" onClick={handleSignupRedirect}>
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
