import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Publish = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { title, description, adminid, assignmentId, startDateTime, endDateTime,firstname } = location.state || {};
  
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const QuestionData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/getallquestionofassigenment`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
          body: JSON.stringify({ assignmentId })
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch data");
      }

      const jsonData = await response.json();
      setData(jsonData.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    QuestionData();
  }, []);
  console.log("lksflksjd,",data)

  const handleSaveForLater = () => {
    alert("Assignment saved for later!");
  };

  const handlePublishAndAssign = () => {
    alert("Assignment published successfully!");
    navigate("/dashboard", { state: { adminid,firstname } });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="p-6 bg-[#FEF4DB] min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Publish & Assign</h1>

      {/* Tab Navigation */}
      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        {/* Other tabs */}
        <p className="px-4 py-2 rounded-lg font-semibold bg-[#ffcd47] text-black">
          Publish & Assign
        </p>
      </div>

      {/* Assignment Details */}
      <div className="flex flex-col gap-4 mb-6">
        <div className="bg-[#E9F5FF] p-4 rounded-lg shadow">
          <h2 className="font-semibold mb-2">Assignment Title</h2>
          <p>{title}</p>
          <h2 className="font-semibold mb-2">Description</h2>
          <p>{description}</p>
        </div>

        {/* Questions & Students */}
        <div className="bg-[#DBF7F5] p-4 rounded-lg shadow">
          <h2 className="font-semibold mb-2">List of Selected Questions</h2>
          <p>Total no. of questions: {data.length}.</p>
          <button 
            className="text-blue-700 font-bold mt-4"
            onClick={openModal}
          >
            Preview Questions
          </button>
        </div>

        {/* Start and End Dates */}
        <div className="flex gap-10 bg-[#FBE2F4] p-4 rounded-lg shadow">
          <div>
            <h2 className="font-semibold mb-2">Start Date</h2>
            <p>{new Date(startDateTime).toLocaleDateString()} {new Date(startDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          </div>
          <div>
            <h2 className="font-semibold mb-2">End Date</h2>
            <p>{new Date(endDateTime).toLocaleDateString()} {new Date(endDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-between">
        <button 
          className="bg-white border-2 border-blue-600 text-blue-600 px-6 py-2 rounded font-semibold hover:bg-blue-100" 
          onClick={handleSaveForLater}
        >
          Save For Later
        </button>
        <button 
          className="bg-blue-600 text-white px-6 py-2 rounded font-semibold hover:bg-blue-700" 
          onClick={handlePublishAndAssign}
        >
          Publish & Assign
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-3/4 rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Selected Student List</h2>
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="px-6 py-4 text-left text-gray-600 font-semibold">S No.</th>
                  <th className="px-6 py-4 text-left text-gray-600 font-semibold">Question</th>
                </tr>
              </thead>
              <tbody>
              {data.map((question, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-6 py-4">{index+1}</td>
                    <td className="px-6 py-4">{question.fields.question}</td>
                  </tr>
                ))}
                
              </tbody>
            </table>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Publish;
