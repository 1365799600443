export const llogo = [
    require('./MATHDINGO.png'),
    
];

export const accurate = [
    require('./Accuracy.png'),
    
];
export const cap = [
    require('./cap.png'),
    
];
export const triagleScale = [
    require('./triagleScale.png'),
    
];