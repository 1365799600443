import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Insight.css';

const defaultProfilePhoto = 'https://skinmtx.com/wp-content/uploads/2016/08/dummy-prod-1.jpg';

const Insight = () => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('asc'); // State for sorting
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentList`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
                        'Content-Type': 'application/json',
                        'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw',
                    },
                    body: JSON.stringify({ Adminid: "65" }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                console.log(result);
                
                // Filter out students whose schoolName is not "haileybury"
                const filtered = result.data.filter(student => student.fields.schoolName === "Test School");
                console.log(filtered)
                setStudents(filtered);
                setFilteredStudents(filtered);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        filterAndSortStudents(query, sortOrder);
    };

    const handleSortChange = (event) => {
        const order = event.target.value;
        setSortOrder(order);
        filterAndSortStudents(searchQuery, order);
    };

    const filterAndSortStudents = (query, order) => {
        let filtered = students.filter(student =>
            student.fields.user_name.toLowerCase().includes(query)
        );

        filtered.sort((a, b) => {
            const nameA = a.fields.user_name.toLowerCase();
            const nameB = b.fields.user_name.toLowerCase();
            return order === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setFilteredStudents(filtered);
    };

    console.log(filteredStudents);

    return (
        <div className='insight-container' style={{ background: "#FFFDF5;" }}>
            <img className='logo-stuudent' src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`}  alt="Logo" />

            {loading && <p className='loading-message'>Loading...</p>}
            {error && <p className='error-message'>Error: {error}</p>}
            <div className='top'>
                <h1 className='student-insight'>Student’s Insights</h1>
                <div className='seacrch'>
                    <div className='search-container'>
                        <input
                            type='text'
                            placeholder='Search by name...'
                            value={searchQuery}
                            onChange={handleSearch}
                            className='search-input'
                        />
                    </div>
                    <div className='sort-container'>
                        <label htmlFor='sort'>Sort by name:</label>
                        <select id='sort' value={sortOrder} onChange={handleSortChange} className='sort-select'>
                            <option value='asc'>Ascending</option>
                            <option value='desc'>Descending</option>
                        </select>
                    </div>
                </div>
            </div>
            {filteredStudents.length > 0 ? (
                <div className='student-grid'>
                    {filteredStudents.map(student => (
                        <div key={student.pk} className='student-card'>
                            <div className='flex justify-center w-[100%]'>
                                <img
                                    src={student.fields.profilephotos || defaultProfilePhoto}
                                    alt={student.fields.user_name || 'Student'}
                                    className='profile-photo'
                                />
                            </div>

                            <div className='student-info flex flex-col justify-between w-[100%]'>
                                <div className='student-info' style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                }}>
                                    <p className='student-name'><strong>Name:</strong> {student.fields.user_name}</p>
                                    <p><strong>Year:</strong> {student.fields.year}</p>
                                    {/* <p><strong>Email:</strong> {student.fields.email}</p> */}
                                </div>
                                <Link to={`/student/${student.pk}`} className='view-details-button'>
                                    Student Insight
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No students found</p>
            )}
        </div>
    );
};

export default Insight;
