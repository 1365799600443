import React, { useEffect, useState } from 'react';

const Library = () => {
    const [question, setQuestion] = useState([]);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/getGenratedQuestion`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
                        "Content-Type": "application/json",
                    },
                }
            );
    
            const data = await response.json();
    
            // Assuming 'assignments' is an array and you want to reverse it
            const reversedAssignments = data.assignments.reverse();
            
            // Store reversed assignments in state
            setQuestion(reversedAssignments);
        } catch (err) {
            console.error("Error fetching data:", err);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="pt-[1.5rem] pr-[7.5rem] pb-[1.5rem] pl-[7.5rem]  mx-auto min-h-screen bg-[#FAF4E5]">
            <img className='logo-stuudent logo-specific-page' src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`} alt="Logo" style={{ marginLeft: "-99px" }} />
            <h1 className="text-3xl font-semibold text-center mb-6">My Library</h1>
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-5 py-4 text-left font-semibold text-gray-700" style={{ whiteSpace: 'nowrap' }}>S No.</th>
                        <th className="px-6 py-4 text-left font-semibold text-gray-700">Question</th>
                    </tr>
                </thead>
                <tbody>
                    {question.map((item, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50">
                            <td className="px-6 py-4 text-sm text-gray-800">{index + 1}</td>
                            <td className="px-6 py-4 text-sm text-gray-800">{item}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Library;
