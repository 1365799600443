// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import "./signup.css";

// const Signup = () => {
//   const [formData, setFormData] = useState({
//     firstname: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     lastname: "",
//   });
//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
//   const [error, setError] = useState("");
//   const [formError, setFormError] = useState("");

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validateForm()) {
//       press();
//     }
//   };

//   const validateForm = () => {
//     const { password, confirmPassword } = formData;
//     const passwordRegex =
//       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

//     if (!passwordRegex.test(password)) {
//       setFormError(
//         "Password must be at least 8 characters long, include one digit, and one special character."
//       );
//       return false;
//     }

//     if (password !== confirmPassword) {
//       setFormError("Passwords do not match.");
//       return false;
//     }

//     setFormError("");
//     return true;
//   };

//   const togglePasswordVisibility = () => {
//     setPasswordVisible(!passwordVisible);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setConfirmPasswordVisible(!confirmPasswordVisible);
//   };

//   const handleSignupRedirect = () => {
//     navigate("/login");
//   };

//   async function press() {
//     let item = {
//       firstname: formData.firstname,
//       email: formData.email,
//       password: formData.password,
//       lastname: formData.lastname,
//     };
//     console.log(item);

//     try {
//       const response = await fetch(
//         "http://34.129.177.103/apiadmin/mathadmin/signup",
//         {
//           method: "POST",
//           headers: {
//             Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(item),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Signup failed");
//       }

//       const result = await response.json();

//       console.log("result", result);
//       navigate("/login");
//     } catch (err) {
//       setError("Signup failed. Please try again.");
//       console.error(err.message);
//     }
//   }

//   return (
//     <div className="flex h-screen w-screen">
//       <div className="flex w-[100%] ">
//         <img
//           className="h-[100%] w-[35%]"
//           src="https://storage.googleapis.com/math_api_bucket/2024/9/18/87e3791d-633b-4ee9-bd27-409257f67ba2/Group2817.png"
//         ></img>
//         <div className="w-[65%] bg-custom-bg  ">
//           <div className="signup-body w-[100%] h-[100%]">
//             <div className="signup-container bg-custom-bg">
//               <h1>Register with MathDingo</h1>
//               <div className="flex justify-around mb-4">
//                 <button className="flex items-center gap-1 bg-button-bg w-[30%]">
//                   <img src="https://storage.googleapis.com/math_api_bucket/2024/9/18/5c2f153f-14af-445a-ab31-ac7c2d316d3c/Google.png"></img>
//                   Google
//                 </button>
//                 <button className="flex items-center gap-1 bg-button-bg w-[30%]">
//                   <img src="https://storage.googleapis.com/math_api_bucket/2024/9/18/4771a2a2-5b0d-4d9f-8f10-97b489da14db/github.png"></img>
//                   Google
//                 </button>
//                 <button className="flex items-center gap-1 bg-button-bg w-[30%]">
//                   <img
//                     className="bg-button-bg"
//                     src="https://storage.googleapis.com/math_api_bucket/2024/9/18/f0b2857d-b66a-40a3-999c-f246eeaad10a/GitLabsvg.svg"
//                   ></img>
//                   Google
//                 </button>
//               </div>
//               <form onSubmit={handleSubmit}>
//                 <div>
//                   <label>Name:</label>
//                   <input
//                     type="text"
//                     name="firstname"
//                     placeholder="Enter your name"
//                     value={formData.firstname}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div>
//                   <label>Email:</label>
//                   <input
//                     type="email"
//                     name="email"
//                     placeholder="Enter your email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div>
//                   <label>Father's Name:</label>
//                   <input
//                     type="text"
//                     name="lastname"
//                     placeholder="Enter your father's name"
//                     value={formData.lastname}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="password-container">
//                   <label>Password:</label>
//                   <input
//                     type={passwordVisible ? "text" : "password"}
//                     name="password"
//                     placeholder="Enter your password"
//                     value={formData.password}
//                     onChange={handleChange}
//                   />
//                   <span
//                     className="password-toggle-icon"
//                     onClick={togglePasswordVisibility}
//                   >
//                     {passwordVisible ? <FaEyeSlash /> : <FaEye />}
//                   </span>
//                 </div>
//                 <div className="password-container">
//                   <label>Confirm Password:</label>
//                   <input
//                     type={confirmPasswordVisible ? "text" : "password"}
//                     name="confirmPassword"
//                     placeholder="Confirm your password"
//                     value={formData.confirmPassword}
//                     onChange={handleChange}
//                   />
//                   <span
//                     className="password-toggle-icon"
//                     onClick={toggleConfirmPasswordVisibility}
//                   >
//                     {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//                   </span>
//                 </div>
//                 {formError && <div className="form-error">{formError}</div>}
//                 <button onClick={press} type="submit">
//                   Sign Up
//                 </button>
//               </form>
//               {error && <div className="error-message">{error}</div>}
//               <h2 className="text-center mt-4">
//                 Already have an account?{" "}
//                 <span
//                   className="text-button-bg"
//                   onClick={handleSignupRedirect}
//                   style={{
//                     cursor: "pointer",
//                   }}
//                 >
//                   Login
//                 </span>
//               </h2>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./signup.css";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    email: "",
    password: "",
    confirmPassword: "",
    schoolName: "Other", // default value for the school name
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [formError, setFormError] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      press();
    }
  };

  const validateForm = () => {
    const { password, confirmPassword } = formData;
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    // if (!passwordRegex.test(password)) {
    //   setFormError(
    //     "Password must be at least 8 characters long, include one digit, and one special character."
    //   );
    //   return false;
    // }

    if (password !== confirmPassword) {
      setFormError("Passwords do not match.");
      return false;
    }

    setFormError("");
    return true;
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSignupRedirect = () => {
    navigate("/login");
  };

  async function press() {
    let item = {
      firstname: formData.firstname,
      lastname:formData.lastname,
      email: formData.email,
      password: formData.password,
      schoolName: formData.schoolName,
    };
    console.log(item);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/signup`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      if (!response.ok) {
        throw new Error("Signup failed");
      }

      const result = await response.json();

      console.log("result", result);
      navigate("/login");
    } catch (err) {
      setError("Signup failed. Please try again.");
      console.error(err.message);
    }
  }

  return (
    <div className="flex h-screen w-screen">
      <div className="flex w-[100%] ">
        <img
          className="h-[100%] w-[35%]"
          src="https://storage.googleapis.com/math_api_bucket/2024/9/18/87e3791d-633b-4ee9-bd27-409257f67ba2/Group2817.png"
        ></img>
        <div className="w-[65%] bg-custom-bg  ">
          <div className="signup-body w-[100%] h-[100%]">
            <div className="signup-container bg-custom-bg">
              <h1>Admin Registration</h1>
              <div className="flex justify-around mb-4">
                {/* <button className="flex items-center gap-1 bg-button-bg w-[30%]">
                  <img src="https://storage.googleapis.com/math_api_bucket/2024/9/18/5c2f153f-14af-445a-ab31-ac7c2d316d3c/Google.png"></img>
                  Google
                </button>
                <button className="flex items-center gap-1 bg-button-bg w-[30%]">
                  <img src="https://storage.googleapis.com/math_api_bucket/2024/9/18/4771a2a2-5b0d-4d9f-8f10-97b489da14db/github.png"></img>
                  Google
                </button>
                <button className="flex items-center gap-1 bg-button-bg w-[30%]">
                  <img
                    className="bg-button-bg"
                    src="https://storage.googleapis.com/math_api_bucket/2024/9/18/f0b2857d-b66a-40a3-999c-f246eeaad10a/GitLabsvg.svg"
                  ></img>
                  Google
                </button> */}
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstname"
                    placeholder="Enter first name"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Enter last name"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="p-[10px]">
                  <label>School Name:</label>
                  <select
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={handleChange}
                  >
                    <option value="Haileybury">Test School</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="password-container">
                  <label>Password:</label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <div className="password-container">
                  <label>Confirm Password:</label>
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm your password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {formError && <div className="form-error">{formError}</div>}
                <button onClick={press} type="submit">
                  Sign Up
                </button>
              </form>
              {error && <div className="error-message">{error}</div>}
              <h2 className="text-center mt-4">
                Already have an account?{" "}
                <span
                  className="text-button-bg"
                  onClick={handleSignupRedirect}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Login
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
