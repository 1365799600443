// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import './Login.css';

// const Login = () => {
//   const [formData, setFormData] = useState({
//     email: '',
//     password: '',
//   });
//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const [unsucessfull, setunsucessfull] = useState('');
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
//   const [adminid,setadminid]=useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     press();
//   };

//   const handleNavigation = () => {
//     navigate('/signup');
//   };

//   const togglePasswordVisibility = () => {
//     setPasswordVisible(!passwordVisible);
//   };

//   async function press() {
//     let item = {
//       email: formData.email,
//       password: formData.password,
//     };
//     console.log(item);

//     try {
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/login`, {
//         method: 'POST',
//         headers: {
//           'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(item),
//       });

//       if (!response.ok) {
//         throw new Error('Login failed');
//       }

//       const result = await response.json();
//       console.log("resultsdkfjlsdjflksjdlfkjsdlfkjsldfkjsdlkf", result.data[0].pk);
//       setadminid(result.data[0].pk)
//       console.log('skdjflkjsdlf',adminid)
//       if (result.status_code == 200) {

//         navigate('/dashboard',{ state: { firstname: result.data[0].fields.firstname,adminid: result.data[0].pk  } })
//       } else {
//         console.log(result.message);
//         setunsucessfull(result.message);
//       }
//     } catch (err) {
//       setError('Login failed. Please retry or sign up if you have no account.');
//       console.error(err.message);
//     }
//   }

//   console.log("inside the login page....")
//   // console.log(process.env.REACT_APP_BASE_URL)
//   return (
//     <div>

//     <div className='Login-body'>
//       <div className='parent-main-container'>
//       <div className="login-container">
//         <div className='flex items-center justify-center'>
//         <img className='mb-8' src='https://storage.googleapis.com/math_api_bucket/2024/9/18/5b12eeec-e474-4d04-9967-7a2f707667fd/Title.png'></img>
//         </div>

//         <form onSubmit={handleSubmit}>
//           <div>
//             <label>Email:</label>
//             <input
//               type="email"
//               name="email"
//               placeholder="Enter your email"
//               value={formData.email}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="password-container">
//             <label>Password:</label>
//             <input
//               type={passwordVisible ? "text" : "password"}
//               name="password"
//               placeholder="Enter your password"
//               value={formData.password}
//               onChange={handleChange}
//             />
//             <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
//               {passwordVisible ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//           <button onClick={handleSubmit} type="submit">Log In</button>
//           <p>{unsucessfull}</p>
//         </form>
//         {error && <div className="error-message">{error}</div>}

//         <h2 className="text-center mt-4">Do not have an account?<span className='text-button-bg' onClick={handleNavigation} style={{
//           cursor: 'pointer'
//         }}>Sign Up</span></h2>

//         {/* <button className="signup-button" >Sign Up</button> */}
//       </div>
//       </div>
//     </div>
//   </div>);
// };

// export default Login;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuth } from "./context/AuthContext"; // Use AuthContext
import "./Login.css";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from context to update auth state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    press();
  };

  const handleNavigation = () => {
    navigate("/signup");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  async function press() {
    let item = {
      userId: formData.email,
      password: formData.password,
    };
    console.log("user id and pass : ", item);
    try {
      // Reset any previous message
      setMessage("");

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/login`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );
      console.log("Result", response);


      if (!response.ok) {
        throw new Error("Login failed");
      }

      const result = await response.json();
      console.log("Result", result);


   
      if (result.status_code == 200) {
        console.log("sldkfjlskdfjlsjf===>>>>>>");
        login({
          adminid: result.data[0].pk,
          firstname: result.data[0].fields.firstname,
        });
        console.log("Navigating to dashboard...");
        navigate("/dashboard");
      } else {
        setMessage(result.message || "Login failed");
      }
    } catch (err) {
      setMessage(
        "Login failed. Please retry or sign up if you have no account."
      );
      console.error(err.message);
    }
  }

  return (
    <div className="Login-body">
      <div className="parent-main-container">
        <div className="login-container">
          <div className="flex items-center justify-center">
            <img
              className="mb-8"
              src="https://storage.googleapis.com/math_api_bucket/2024/9/18/5b12eeec-e474-4d04-9967-7a2f707667fd/Title.png"
              alt="Logo"
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">UserId:</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Enter your UserId"
                value={formData.email}
                onChange={handleChange}
                required
                autoComplete="email"
              />
            </div>
            <div className="password-container">
              <label htmlFor="password">Password:</label>
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="current-password"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <button type="submit">Log In</button>
            {message && (
              <p
                className={`message ${
                  message.includes("failed")
                    ? "error-message"
                    : "success-message"
                }`}
              >
                {message}
              </p>
            )}
          </form>
          <h2 className="text-center mt-4">
            Do not have an account?{" "}
            <span
              className="text-button-bg"
              onClick={handleNavigation}
              style={{ cursor: "pointer" }}
            >
              Sign Up
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Login;
