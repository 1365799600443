import React, { useState, useEffect } from "react";
import "./DashBrd.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import { useAuth } from "./context/AuthContext";

const Dashboard = () => {
  const [years, setYears] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCard, setSelectedCard] = useState("standards");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearid, setSelectedYearid] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLessons, setSelectedLessons] = useState(new Set()); // To track selected lessons
  const [selectedYears, setSelectedYears] = useState(new Set()); // New state for selected years
  const [trigger, setTrigger] = useState(false);

  const lessonsPerPage = 8;

  const navigate = useNavigate();
  const location = useLocation();
  const { firstname, adminid } = location.state || {};
  const { authState ,user} = useAuth(); 
  const fetchYears = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/allyearsAdmin`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch data");
      }
      const result = await response.json();
      setYears(result.data);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchYears();
  }, [trigger]);

  const handleItemClick = (id, year) => {
    navigate("/lessons", {
      state: { yearId: id, firstName: year },
    });
  };

  const handleItemQuestion = (id, lesson) => {
    navigate("/question", {
      state: { yearId: selectedYearid, LessonId: id, LessonName: lesson },
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastLesson = currentPage * lessonsPerPage;
  const indexOfFirstLesson = indexOfLastLesson - lessonsPerPage;
  const currentLessons = lessons.slice(indexOfFirstLesson, indexOfLastLesson);

  const handleSignupRedirect = () => {
    navigate("/login");
  };

  const handleInsightRedirect = () => {
    navigate("/insight");
  };

  const handleToggleYearSelection = async (yearId, isSelect) => {
    console.log("Year ID:", yearId, "Selection Status:", isSelect);
    try {
      const headers = {
        Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
        "Content-Type": "application/json",
        jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
      };

      if (isSelect === "1") {
        // Year is selected, now unselect it
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/deleteYear`,
          { Yearid: yearId, adminId: adminid },
          { headers }
        );

        console.log(`Year ${yearId} unselected. Response:`, response.data);
        setSelectedYears((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(yearId); // Remove the unselected year
          return newSelected;
        });
      } else if (isSelect === "0") {
        // Year is not selected, now select it
        const yearList = Array.from(selectedYears).map((id) => ({
          YearId: id,
        })); // Get the current selected years
        yearList.push({ YearId: yearId }); // Add the new yearId to the list

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/selectedyear`,
          { Yearlist: yearList },
          { headers }
        );

        console.log("response : ", response);

        console.log(`Year ${yearId} selected. Response:`, response.data);
        setSelectedYears((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.add(yearId); // Add the newly selected year
          return newSelected;
        });
      }
    } catch (error) {
      console.error(
        "Error toggling year selection:",
        error.response ? error.response.data : error
      );
      setError("Failed to toggle year selection."); // Set error state if needed
    }
    setTrigger((prev) => !prev); // Changing state to trigger useEffect
  };

  const handleToggleLessonSelection = async (lessonId, yearId, isSelect) => {
    console.log(
      "Lesson ID:",
      lessonId,
      "Year ID:",
      yearId,
      "Selection Status:",
      isSelect
    );

    try {
      const headers = {
        Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
        "Content-Type": "application/json",
        jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
      };

      if (isSelect === "1") {
        // Lesson is selected, now unselect it
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/deleteLesson`,
          { Lessonid: lessonId },
          { headers }
        );

        setSelectedLessons((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(lessonId); // Remove the unselected lesson
          return newSelected;
        });
      } else if (isSelect === "0") {
        // Lesson is not selected, now select it
        const lessonList = Array.from(selectedLessons).map((id) => ({
          YearId: yearId,
          LessonId: id,
        })); // Get the current selected lessons

        // Add the new lessonId and yearId to the list
        lessonList.push({ YearId: yearId, LessonId: lessonId });

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/selectedlesson`,
          { Lessonlist: lessonList },
          { headers }
        );

        console.log(`Lesson ${lessonId} selected. Response:`, response.data);
        setSelectedLessons((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.add(lessonId); // Add the newly selected lesson
          return newSelected;
        });
      }
    } catch (error) {
      console.error(
        "Error toggling lesson selection:",
        error.response ? error.response.data : error
      );
      setError("Failed to toggle lesson selection."); // Set error state if needed
    }
  };
  return (
    <div className="parent">
      <div className="dashboard-container">
        <Sidebar
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          handleInsightRedirect={handleInsightRedirect}
          handleSignupRedirect={handleSignupRedirect}
          adminid={adminid}
          firstname={firstname}
        />
      </div>
      <div className="parent-main vertical">
        <h2 className="user-name ">Hi {user.firstname} 👋🏼</h2>

        {selectedYear ? (
          <div className="lessondashboard">
            <button
              className="back-button"
              onClick={() => setSelectedYear(null)}
            >
              Back
            </button>
            <h1 className="headings">{selectedYear}</h1>
            <div className="lesson vertical">
              {currentLessons.map(
                (item) => (
                  console.log(currentLessons),
                  (
                    <div className="vertical" key={item.pk}>
                      <div className="lesson-item">
                        <h2 className="lesson-named ">
                          <p className="w-[20%]">{item.fields.lession}</p>
                          <button
                            className="viewdetails w-[100px]"
                            onClick={() =>
                              handleToggleLessonSelection(
                                item.pk,
                                selectedYearid,
                                item.fields.isSelect
                              )
                            }
                          >
                            {item.fields.isSelect === "1"
                              ? "Unselect"
                              : "Select"}
                          </button>
                          <p
                            className="viewdetails"
                            onClick={() =>
                              handleItemQuestion(item.pk, item.fields.lession)
                            }
                          >
                            view Details
                          </p>
                        </h2>
                      </div>
                      <div className="seperation"></div>
                    </div>
                  )
                )
              )}
              {error && <p>Error: {error}</p>}
            </div>
            <div className="pagination">
              {[
                ...Array(Math.ceil(lessons.length / lessonsPerPage)).keys(),
              ].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`page-button ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  {number + 1}
                </button>
              ))}
            </div>
          </div>
        ) : selectedCard === "standards" ? (
          <div className="yeardashboard">
            <h2 className="heading-dashboard">Years of MathDingo</h2>
            <h3 className="sub-heading-dashboard">
              You can find all the student details of all the years
            </h3>
            {years.map((item) => (
  <div className="year-box-parent vertical" key={item.pk}>
    <div className="year-box flex ">
      <p className="year-name w-[10%]">{item.fields.year}</p>
      <button
        className={`w-[100px] text-white px-4 py-2 rounded ${
          item.fields.isSelect === "1"
            ? "bg-green-500 hover:bg-green-600"
            : "bg-red-500 hover:bg-red-600"
        }`}
        onClick={() =>
          handleToggleYearSelection(item.pk, item.fields.isSelect)
        }
      >
        {item.fields.isSelect === "1" ? "Disable" : "Enable"}
      </button>

      <button
        className={`viewdetails flex items-center text-blue-600 px-4 py-2 rounded ${
          item.fields.isSelect === "0"
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-blue-100"
        }`}
        onClick={() => handleItemClick(item.pk, item.fields.year)}
        disabled={item.fields.isSelect === "0"}
      >
        Manage Content
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="11"
          viewBox="0 0 7 11"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.350946 0.210453C0.221273 0.345074 0.148438 0.527559 0.148438 0.717823C0.148438 0.908088 0.221273 1.09057 0.350946 1.22519L4.47702 5.50433L0.350023 9.78348C0.223948 9.91895 0.154235 10.1003 0.155897 10.2886C0.15756 10.4768 0.230466 10.6569 0.358913 10.7899C0.487359 10.923 0.661069 10.9984 0.842628 11C1.02419 11.0015 1.19907 10.9291 1.32961 10.7982L5.94593 6.0117C6.0756 5.87708 6.14844 5.6946 6.14844 5.50433C6.14844 5.31407 6.0756 5.13159 5.94593 4.99696L1.32961 0.210453C1.2653 0.143734 1.18895 0.0908072 1.10491 0.0546967C1.02087 0.0185862 0.930786 0 0.839815 0C0.748844 0 0.658765 0.0185862 0.574724 0.0546967C0.490683 0.0908072 0.414329 0.143734 0.350023 0.210453H0.350946Z"
            fill="#08059B"
          />
        </svg>
      </button>
    </div>
  </div>
))}

          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
