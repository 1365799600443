import React, { useState } from 'react';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [link, setLink] = useState(null);

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }

    setUploading(true);
    setError(null); // Reset any previous errors

    const formData = new FormData();
    formData.append("File", file); // Append file to FormData with the name "File"

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/fileuploadingooglecloud`, {
        method: 'POST',
        headers: {
          'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
          'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTkyMjE1LCJpYXQiOjE3Mjg5OTE5MTUsImp0aSI6IjczMGYzMDIxNGQxMzQ2OWJiYmNlNTRkMjU5ZTRjMTI1IiwidXNlcl9pZCI6Ik5vbmUifQ.-_y3cDpKqaFaPkuHBTlMlpk-iEOMsLQKpzV_j3ymyGA',
        },
        body: formData, // Attach the FormData as the body
      });

      if (!response.ok) {
        throw new Error('Failed to upload file');
      }

      const data = await response.json();
      alert('File uploaded successfully!');
      setLink(data.data[0]);

      // Extract and set the uploaded file URL from the response
      if (data && data.data && data.data.length > 0) {
        setFileUrl(data.data[0]); // Save the URL
      } else {
        setError('No file URL received from the server');
      }

    } catch (error) {
      setError(error.message);
      alert('Error uploading file');
    } finally {
      setUploading(false);
    }
  };

  // Function to copy the link to clipboard
  const handleCopyToClipboard = () => {
    if (link) {
      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = link;
      document.body.appendChild(textarea);

      // Select and copy the text inside the textarea
      textarea.select();
      textarea.setSelectionRange(0, 99999); // For mobile devices

      try {
        // Try to copy the selected text to the clipboard
        document.execCommand('copy');
        alert('Link copied to clipboard!');
      } catch (err) {
        alert('Failed to copy link: ' + err);
      }

      // Remove the textarea element after copying
      document.body.removeChild(textarea);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg space-y-4">
      <h1 className="text-2xl font-semibold text-center text-gray-800">File Upload</h1>
      
      {/* File input */}
      <div className="mb-4">
        <label className="block text-md text-gray-700 mb-2" htmlFor="file-upload">Choose a file to upload:</label>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          className="w-full p-2 border-2 border-gray-300 rounded-md text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
        />
      </div>
      
      {/* Upload button */}
      <button
        onClick={handleFileUpload}
        disabled={uploading}
        className="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 transition duration-300"
      >
        {uploading ? (
          <div className="flex justify-center items-center space-x-2">
            <div className="w-5 h-5 border-4 border-t-4 border-blue-400 border-solid rounded-full animate-spin"></div>
            <span>Uploading...</span>
          </div>
        ) : (
          'Upload File'
        )}
      </button>
      
      {/* Error message */}
      {error && <p className="text-red-500 text-center mt-4">{error}</p>}

      {/* Success message with link */}
      {fileUrl && (
        <div className="mt-4 text-center space-y-4">
          <h2 className="text-xl text-green-600 font-medium">File Uploaded Successfully!</h2>
          <p className="text-gray-700">Click below to view your uploaded file:</p>
          
          <div className="flex justify-center items-center space-x-4">
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-700 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
              View Uploaded File
            </a>
            {/* Copy Button */}
            <button
              onClick={handleCopyToClipboard}
              className="bg-gray-700 text-white p-2 rounded-md hover:bg-gray-600 transition duration-200"
            >
              Copy Link
            </button>
          </div>
          
          {link && (
            <p className="text-gray-600 mt-4 text-sm">File URL: <span className="text-blue-500">{link}</span></p>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
