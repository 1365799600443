import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AssignMarks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedQuestions = [], assignmentId, yearId, title, description,adminid ,firstname} = location.state || {};

  // Initialize marks state
  const [marks, setMarks] = useState(
    selectedQuestions.reduce((acc, question) => {
      acc[question.id] = ""; // Initialize each question's marks as empty
      return acc;
    }, {})
  );

  // Handle marks input change
  const handleMarksChange = (id, value) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      [id]: value,
    }));
  };

  // Calculate total marks
  const calculateTotalMarks = () => {
    return Object.values(marks)
      .filter((mark) => mark !== "") // Exclude empty values
      .reduce((total, mark) => total + parseFloat(mark || 0), 0); // Sum all marks
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure all marks are filled
    const allMarksFilled = selectedQuestions.every((question) => marks[question.id] !== "");

    if (!allMarksFilled) {
      alert("Please assign marks to all questions.");
      return;
    }

    try {
      // Send marks to the backend for each question
      const responses = await Promise.all(
        selectedQuestions.map(async (question) => {
          const formData = new FormData();
          formData.append("assignmentId", assignmentId);
          formData.append("questionId", question.id);
          formData.append("marks", marks[question.id]);

          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/questionAssignment`,
            {
              method: "POST",
              headers: {
                Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
              },
              body: formData,
            }
          );

          if (!response.ok) {
            const errorDetails = await response.text();
            console.error("Failed response:", errorDetails);
            throw new Error(`Failed to assign marks for question: ${question.id}`);
          }

          return response.json();
        })
      );

      alert("Marks assigned successfully!");
      navigate("/assignTimeline", { state: { marks, yearId, assignmentId, title, description,adminid ,firstname} });
    } catch (error) {
      console.error("Error assigning marks:", error);
      alert("An error occurred while assigning marks. Please try again.");
    }
  };

  return (
    <div className="p-6 bg-[#FEF4DB] min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Assign Marks</h1>

      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        <p className="px-4 py-2 rounded-lg font-semibold">Task Details</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign to</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Select Question</p>
        <p className="px-4 py-2 rounded-lg font-semibold bg-[#ffcd47] text-black">Assign Marks</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign Timeline</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Publish & Assign</p>
      </div>

      <div className="overflow-x-auto border rounded-lg shadow-sm mb-6">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">Question</th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">Marks</th>
              <th className="px-6 py-4 font-semibold">Total Marks: {calculateTotalMarks()}</th>
            </tr>
          </thead>
          <tbody>
            {selectedQuestions.map((question) => (
              <tr key={question.id} className="border-b">
                <td className="px-6 py-4">{question.question}</td>
                <td className="px-6 py-4">
                  <input
                    type="number"
                    value={marks[question.id]}
                    onChange={(e) => handleMarksChange(question.id, e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    placeholder="Enter marks"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-evenly items-center">
        <button
          className="bg-gray-600 text-white px-6 py-2 rounded hover:bg-gray-700"
          onClick={() => navigate("/selectQuestion")}
        >
          Back
        </button>
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default AssignMarks;
