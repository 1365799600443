import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AssignTo = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]); // To store selected students
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [years, setYears] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(1);

  console.log("selectedStudent:=>", selectedStudents);

  const location = useLocation();
  const { id, title, description, adminid ,yearID,firstname} = location.state || {};
  console.log("yearid............>>>>>>>",yearID)
  console.log("Assignmetn id in Assingto: ", id);

  const fetchYears = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/allyearsAdmin`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch data");
      }
      const result = await response.json();
      setYears(result.data);

      console.log(years);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchYears();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/allStudent`,
          {
            method: "POST",
            headers: {
              Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
              "Content-Type": "application/json",
              jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
            },
            body: JSON.stringify({ yearId: yearID }),
          }
        );
  
        if (!response.ok) throw new Error("Failed to fetch data");
  
        const result = await response.json();
        console.log("Fetched result:", result);
  
        // Filtering students with schoolName === 'Test School'
        const filtered = result.data.filter(
          (student) => student.fields.schoolName === "Test School"
        );
  
        // Assuming 'pk' is a property in each student object that you need to use
        const studentIds = filtered.map((student) => student.pk);
  
        setStudents(studentIds);
        setFilteredStudents(filtered);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [selectedYearId]);
  
  const handleCheckboxChange = (student) => {
    // Toggle student selection
    if (selectedStudents.includes(student)) {
      setSelectedStudents(selectedStudents.filter((s) => s !== student));
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  const handleSaveAndNext = async () => {
    try {
      const studentIds = selectedStudents.map((student) => student.pk); // Assuming `student.pk` contains student IDs
      console.log("sdklfkjsldkfj   id    =  ", studentIds);
      // Calling the API to assign the students to the assignment
      const formData = new FormData();
      formData.append("assignmentId", id);
      formData.append("studentId", JSON.stringify(studentIds));

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentAssignment`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            jwt: "your-jwt-here", // Add correct JWT here
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to assign students.");
      }

      // Navigate to the next page
      navigate("/selectQuestion", {
        state: {
          AssignmentId: id,
          yearID,
          title,
          description,
          adminid,
          firstname
        },
      });
    } catch (err) {
      setError(err.message);
    }
  };
  const handleYearChange = (event) => {
    const newYearId = Number(event.target.value);
    if (newYearId !== selectedYearId) {
      setSelectedYearId(newYearId);
      // localStorage.setItem(`selectedYear_${id}`, newYearId);
    }
  };

  console.log("yearsldkjflskjf", selectedYearId);
  // console.log("selected studetn",selectedStudents)

  return (
    <div className="pt-[1.5rem] pr-[7.5rem] pb-[1.5rem] pl-[7.5rem]  mx-auto min-h-screen bg-[#FAF4E5]">
      <img
        className="logo-stuudent"
        src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`} 
        alt="Logo"
      />

      <h1 className="text-3xl font-bold mt-[50px] ml-[50%] mb-4">Assignment</h1>

      {/* Tab Navigation */}
      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        <p className="px-4 py-2 rounded-lg font-semibold">Task Details</p>
        <p className="px-4 py-2 rounded-lg font-semibold bg-[#ffcd47] text-black">
          Assign to
        </p>
        <p className="px-4 py-2 rounded-lg font-semibold">Select Question</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign Marks</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign Timeline</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Publish & Assign</p>
      </div>

      {/* Student List Table */}
      <div className="overflow-x-auto border bg-white rounded-lg shadow-sm ">
        <table className="min-w-full ">
          <thead>
            <tr>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                Select
              </th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                Profile
              </th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                Name
              </th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                Year
              </th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                Email
              </th>
              <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                <td className="bg-white text-black rounded-r-lg px-2 py-2">
                  
                </td>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredStudents && filteredStudents.length > 0 ? (
              filteredStudents.map((student, index) => (
                <tr key={index} className="border-b">
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedStudents.includes(student)}
                      onChange={() => handleCheckboxChange(student)}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <img
                      src={student.fields.profilephotos}
                      className="w-[60px]"
                      alt="profile"
                    />
                  </td>
                  <td className="px-6 py-4">{student.fields.user_name}</td>
                  <td className="px-6 py-4">{student.fields.year}</td>
                  <td className="px-6 py-4">{student.fields.email}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center">
                  No students found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Bottom Buttons */}
      <div className="flex justify-evenly items-center mt-6">
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
          onClick={() => setShowModal(true)}
        >
          Preview Selected List
        </button>
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
          onClick={handleSaveAndNext}
        >
          Save & Next
        </button>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-3/4 rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Selected Student List</h2>
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                    Profile
                  </th>
                  <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                    Name
                  </th>
                  <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                    Year
                  </th>
                  <th className="px-6 py-4 text-left text-gray-600 font-semibold">
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedStudents.map((student, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-6 py-4">
                      <img
                        src={student.fields.profilephotos}
                        className="w-[40px]"
                        alt="profile"
                      />
                    </td>
                    <td className="px-6 py-4">{student.fields.user_name}</td>
                    <td className="px-6 py-4">{student.fields.year}</td>
                    <td className="px-6 py-4">{student.fields.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignTo;
