import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AssignTimeline = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const {yearId,assignmentId,title,description,adminid,firstname} =location.state ||{};
  console.log("yeare idd",assignmentId,yearId)

  // States for datetime inputs
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const handleSaveAndNext = async () => {
    // Validate that both dates are filled
    if (!startDateTime || !endDateTime) {
      alert("Please fill out both start and end date-time.");
      return;
    }

    // Prepare the request body for API call
    const requestBody = {
      assignmentId: assignmentId,
      isPublish: 1, // Assuming this means 'publish'
      startTime: startDateTime,
      endTime: endDateTime,
      yearId: yearId,
    };

    try {
      // API call to update the assignment
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/updateAssignment`,
        {
          method: "POST",
          headers: {
            'Authorization': 'Token a6039607dfd014db1c2ff40d25f14c4a715f0282',
            'Content-Type': 'application/json',
            'jwt': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTkyMjE1LCJpYXQiOjE3Mjg5OTE5MTUsImp0aSI6IjczMGYzMDIxNGQxMzQ2OWJiYmNlNTRkMjU5ZTRjMTI1IiwidXNlcl9pZCI6Ik5vbmUifQ.-_y3cDpKqaFaPkuHBTlMlpk-iEOMsLQKpzV_j3ymyGA',
        },
          body: JSON.stringify(requestBody),
        }
      );

      // Handle response
      if (!response.ok) {
        throw new Error("Failed to update assignment");
      }

      const data = await response.json();
      console.log("API Response:", data);

      // Navigate to the "Publish & Assign" page
      navigate("/publish", { state: { startDateTime, endDateTime,title,description,adminid ,assignmentId,startDateTime,endDateTime,firstname} });

    } catch (error) {
      console.error("Error updating assignment:", error);
      alert("Failed to update assignment. Please try again.");
    }


  const handleSaveAndNext = () => {
    // Validate datetime inputs
    if (!startDateTime || !endDateTime) {
      alert("Please fill out both start and end date-time.");
      return;
    }

    // Navigate to the next screen, for example, "Publish & Assign"
    navigate("/publish", { state: { startDateTime, endDateTime } });
  };
  }
  return (
    <div className="p-6 bg-[#FEF4DB] min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Assign Timeline</h1>

      {/* Tab Navigation */}
      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        <p className="px-4 py-2 rounded-lg font-semibold">Task Details</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign to</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Select Question</p>
        <p className="px-4 py-2 rounded-lg font-semibold">Assign Marks</p>
        <p className="px-4 py-2 rounded-lg font-semibold bg-[#ffcd47] text-black">
          Assign Timeline
        </p>
        <p className="px-4 py-2 rounded-lg font-semibold">Publish & Assign</p>
      </div>

      {/* Worksheet Info */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2 flex items-center">
            <span className="text-blue-500 mr-2">🏫</span>  Title 
          </h2>
          <p className="text-gray-700">{title}</p>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2 flex items-center">
            <span className="text-yellow-500 mr-2">👤</span> Description 
          </h2>
          <p className="text-gray-700">
            {description}
          </p>
        </div>
      </div>

      {/* DateTime Pickers */}
      <div className="grid grid-cols-2 gap-6 mb-8">
        {/* Start Date and Time */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">
            Start Date & Time
          </label>
          <input
    type="datetime-local"
    value={startDateTime}
    onChange={(e) => setStartDateTime(e.target.value)}
    min={new Date().toISOString().slice(0, 16)} 
    className="w-full px-4 py-2 border rounded-lg"
  />

        </div>
        {/* End Date and Time */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">
            End Date & Time
          </label>
          <input
    type="datetime-local"
    value={endDateTime}
    onChange={(e) => setEndDateTime(e.target.value)}
    min={startDateTime || new Date().toISOString().slice(0, 16)}  
    className="w-full px-4 py-2 border rounded-lg"
  />

        </div>
      </div>

      {/* Save & Next Button */}
      <div className="flex justify-end">
        <button
          className="bg-blue-600 text-white px-8 py-3 rounded hover:bg-blue-700"
          onClick={handleSaveAndNext}
        >
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default AssignTimeline;
